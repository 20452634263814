import { IsProdMode } from "../../utils/AppSetting";

// Web API url
//export const APIURL = (IsProdMode) ? "http://testingimsapi.echemportal.net/api/" : "https://localhost:44321/api/";

// export const APIURL = (IsProdMode) ? process.env.REACT_APP_PreProdApiURL : process.env.REACT_APP_DevApiURL;
//export const APIURL = "https://localhost:7292/api/";
export const APIURL = "https://sfc-api.sgfashioncouncil.org.sg/api/";

//Authentication services
export const AdminLogin = "Account/AdminLogin";
export const AzureLogin = "Account/AzureLogin";

//Common Services
export const GetAllAreaOfExpertise = process.env.REACT_APP_GetAllAreaOfExpertise;
export const GetAllBusinessSectors = process.env.REACT_APP_GetAllBusinessSectors;
export const GetAllChapters = process.env.REACT_APP_GetAllChapters;
export const GetAllCountries = process.env.REACT_APP_GetAllCountries;
export const GetAllStatus = process.env.REACT_APP_GetAllStatus;
export const uploadPDF = "Common/UploadPDF";
export const StoreBase64Image = process.env.REACT_APP_StoreBase64Image;
export const GetImage = "Common/GetImage";
export const GetAllDropdownsForCorporateMember = "Common/GetAllDropdownsForCorporateMember";
export const GetPDF = "Common/GetPDF?type={0}&&fileName={1}"
export const DownloadImage = "Common/DownloadImage?type={0}&&fileName={1}"
export const GetPaymentFiles = "Common/GetPaymentFiles?id={0}&&id2={1}"

// Admin 
export const AddEditAdminDetails = "Account/AddEditAdminDetails";
export const GetRolesForAdmin = "Account/GetRolesForAdmin";
export const GetAdminDetailList = "Account/GetAdminDetailList";
export const GetAdminDetailById = "Account/GetAdminDetailById?id={0}";
export const DeleteAdminDetailsById = "Account/DeleteAdminDetailsById?id={0}";
export const ForgotPassword = "Account/ForgotPassword";
export const ResetPassword = "Account/ResetPassword";

// Membership Pricing Services
export const AddMembershipPricing = "MembershipPricing/AddEditMembershipPricing";
export const GetMembershipPricing = "MembershipPricing/GetMembershipPricing";

// Membership Campaign Services
export const AddEditMembershipCampaign = "MembershipPricing/AddEditMembershipCampaign";
export const GetMembershipCampaignList = "MembershipPricing/GetMembershipCampaignList";
export const ActiveInActiveCampaign = "MembershipPricing/ActiveInActiveMembershipCampaignStatusById";
export const GetMembershipCampaignById = "MembershipPricing/GetMembershipCampaignById";


//Membership Task List Services
export const GetAllTaskList = "MembershipTask/GetAllTaskList";
export const GetTaskByCompanyId = "MembershipTask/GetTaskByCompanyId?id={0}";
export const GetAllDropdownsForMembershipTaskList = "MembershipTask/GetAllDropdownsForMembershipTaskList";
export const GetAllPriceTables = "MembershipTask/GetAllPriceTables";
export const GetExistingDiscountTableByTaskId = "MembershipTask/GetExistingDiscountTableByTaskId?id={0}";
export const AddEditMembershipTaskAndSendForApproval = "MembershipTask/AddEditMembershipTaskAndSendForApproval";
export const SendQuotationEmailToCorporateMember = "MembershipTask/SendQuotationEmailToCorporateMember";
export const GetCalculatedFinalPrice = "MembershipTask/GetCalculatedFinalPrice";
export const SendEscalateMailToMember = "MembershipTask/SendEscalateMailToMember";
export const AddEditMembershipTaskAndSendPaymentLink = "MembershipTask/AddEditMembershipTaskAndSendPaymentLink";
export const DownloadQuotation = "MembershipTask/DownloadQuotation?id={0}";
export const DeleteTaskListDetailsByTaskId = "MembershipTask/DeleteTaskListDetailsByTaskId?id={0}&&id1={1}";

//Corporate Member Service 
export const GetMembershipCorporateList = "CorporateMember/GetMembershipCorporateList";
export const ActiveInActiveMembershipCorporate = "CorporateMember/ActiveInActiveMembershipCorporate";
export const DeleteMembershipCorporate = "CorporateMember/DeleteMembershipCorporate";
export const GetAllCorporateStatus = "CorporateMember/GetAllCorporateStatus";
export const GetCorporateMemberById = "CorporateMember/GetCorporateMemberById";
export const MarkIndivisualMemberAsNonMember = "MembershipCorporate/MarkIndivisualMemberAsNonMember?id={0}";
export const DeleteCorporateOrganisationRole = "CorporateMember/DeleteCorporateOrganisationRole"

//Financial
export const UpdateFormPaymentHistory = "Booking/UpdateFormPaymentHistory?referenceNo={0}&eventVisitorId={1}";
export const GetAllDropDownForEntryManagement = "Finance/GetAllDropDownForEntryManagement?id={0}";
export const GetEventPaymentReconcilationList = "Finance/GetEventPaymentReconcilationList";
export const GetPaymentProofListDetailsByPaymentProofId = "Finance/GetPaymentProofListDetailsByPaymentProofId?id={0}&typeid={1}";
export const GetOutstandingInvoicesList = "Finance/GetOutstandingInvoicesList";
export const AddProofInPaymentProofMapping = "Finance/AddProofInPaymentProofMapping";
export const SavePaymentReconcilationDetailsByAdmin = "Finance/SavePaymentReconcilationDetailsByAdmin";
export const AcceptTotalPaidAmountInReconcilation = "Booking/AcceptTotalPaidAmountInReconcilation?id={0}";
export const AcceptTotalMembershipPaidAmountInReconciliation = "Finance/AcceptTotalMembershipPaidAmountInReconciliation";
export const GetFinanceGSTSettingList = "Finance/GetFinanceGSTSettingList";
export const GetFinanceClassCodeSettingList = "Finance/GetFinanceClassCodeSettingList";
export const GetFinanceAccountCodeSettingList = "Finance/GetFinanceAccountCodeSettingList";
export const GetFinanceCreditNoteApprovalList = "Finance/GetFinanceCreditNoteApprovalList";
export const AddEditFinanceGST = "Finance/AddEditFinanceGST";
export const AddEditFinanceClassCode = "Finance/AddEditFinanceClassCode";
export const AddEditFinanceAccountCode = "Finance/AddEditFinanceAccountCode";
export const AddEditFinanceCreditNoteApproval = "Finance/AddEditFinanceCreditNoteApproval";
export const GetCreditNoteInfoForCorporateMember = "Finance/GetCreditNoteInfoForCorporateMember?id={0}&id2={1}";
export const GetCancellationReasonForCreditNote = "Finance/GetCancellationReasonForCreditNote";
export const AddFinanceCreditNote = "Finance/AddFinanceCreditNote";
export const DeleteFinanceGlobal = "Finance/DeleteFinanceGlobal";
export const GetFinanceCreditNoteStatusList = "Finance/GetFinanceCreditNoteStatusList";
export const GetAllFinanceCreditNoteList = "Finance/GetAllFinanceCreditNoteList";
export const FinanceApproveRejectCreditNoteApproval = "Finance/FinanceApproveRejectCreditNoteApproval";
export const FinanceUpdateCreditNote = "Finance/FinanceUpdateCreditNote";



//Corporate Membership Service
// export const GetAllDropdownsForCorporateMember = "MembershipCorporate/GetAllDropdownsForCorporateMember";
export const GetCorporateEngagementDetailsById = "MembershipCorporate/GetCorporateEngagementDetailsById?id={0}";
export const AddEditSIISCorporateAccountInformation = "MembershipCorporate/AddEditCorporateAccountInformation";
export const GetCorporateAccountInformationById = "MembershipCorporate/GetCorporateAccountInformationById?id={0}";
export const GetEngagementTrackingListById = "MembershipCorporate/GetEngagementTrackingListById?id={0}";
export const GetCorporateMembershipSubscriptionById = "MembershipCorporate/GetCorporateMembershipSubscriptionById?id={0}";
export const GetOrganisationProfileById = "MembershipCorporate/GetOrganisationProfileById";
export const GetOrganisationKeyRolesListById = "MembershipCorporate/GetOrganisationKeyRolesListById?id={0}";
export const GetOrganisationKeyRolesById = "CorporateMember/GetCorporateOrganisationRoleById?id={0}";
export const GetEmployeeInformationById = "MembershipCorporate/GetEmployeeInformationById?id={0}";
export const GetCorporateFinancialInfo = "MembershipCorporate/GetCorporateFinancialInfo?id={0}";
export const AddEditCorporateOrganisationRole = "CorporateMember/AddEditCorporateOrganisationRole";
export const EdiCorporateEmployeeDetails = "MembershipCorporate/EdiCorporateEmployeeDetails";
export const GetEmployeeInfomationForCorporateMemberAdmin = "MembershipCorporate/GetEmployeeInfomationForCorporateMemberAdmin?id={0}&nameSearch={1}";
export const AddEditCorporateEngagementTracking = "MembershipCorporate/AddEditCorporateEngagementTracking";
export const AddEditCorporateMembershipSubscription = "MembershipCorporate/AddEditCorporateMembershipSubscription";
export const AddEditCorporateCompanyProfile = "MembershipCorporate/AddEditCompanyProfile";
export const GetCorporateFinanceReceiptInvoice = "MembershipCorporate/GetCorporateFinanceReceiptInvoice?id={0}&id2={1}&id3={2}";
export const GetCorporateFinanceForCreditNote = "MembershipCorporate/GetCorporateFinanceForCreditNote?id={0}";
export const AddEditBusinessPresenceAndMarketInterest = "MembershipCorporate/AddEditBusinessPresenceAndMarketInterest";
export const GetBusinessPresenceAndMarketInterestById = "MembershipCorporate/GetBusinessPresenceAndMarketInterestById?id={0}";
export const AddEditFinanceCardDetails = "MembershipCorporate/AddEditFinanceCardDetails";
export const SearchIndividualMemberByEmailForOrgRole = "CorporateMember/SearchIndividualMemberByEmailForOrgRole";
export const GetCorporateRepresentativeDetailsById = "MembershipCorporate/GetCorporateRepresentativeDetailsById?id={0}";
export const AddEditRepresantativeInformationById = "MembershipCorporate/AddEditRepresantativeInformationById";

//Individual Membership Service
export const GetAllDropdownsForIndividualMember = "Common/GetAllDropdownsForIndividualMember";
export const AddEditIndividualSIISInformation = "IndividualMember/AddEditIndividualSIISInformation";
export const AddEditEngagementTracking = "IndividualMember/AddEditEngagementTracking";
export const AddEditIndividualCompanyInformation = "IndividualMember/UpdateCompanyInformation";
export const GetMembershipCampaignByDate = "MembershipPricing/GetMembershipCampaignByDate";


//Individual Member Service 
export const GetIndividualMemberList = "IndividualMember/GetIndividualMemberList";
export const ActiveInActiveIndividualMember = "IndividualMember/ActiveInActiveIndividualMember";
export const DeleteIndividualMember = "IndividualMember/DeleteIndividualMember";
export const GetIndividualSIISInformation = "IndividualMember/GetIndividualSIISInformation?id={0}";
export const GetIndividualEngagementTrackingList = "IndividualMember/GetIndividualEngagementTrackingList?id={0}";
export const GetIndividualEngagementTrackingById = "IndividualMember/GetIndividualEngagementTrackingById?id={0}";
export const GetIndividualMemberSubscription = "IndividualMember/GetIndividualMemberSubscription?id={0}";
export const GetIndividualOthersInformationById = "IndividualMember/GetIndividualOthersInformationById?id={0}";
export const GetIndividualBasicInformation = "IndividualMember/GetIndividualBasicInformation?id={0}";
export const GetBookingListForIndividualMemberById = "IndividualMember/GetBookingListForIndividualMemberById?id={0}";
export const GetIndividualInstituteInformationDetailsById = "IndividualMember/GetIndividualInstituteInformationDetailsById?id={0}";
export const GetIndividualInformationByIndividualId = "IndividualMember/GetIndividualInformationByIndividualId?id={0}";
export const AddEditIndividualMemberSubscription = "IndividualMember/AddEditIndividualMemberSubscription";
export const AddEditIndividualInstituteInformationDetails = "IndividualMember/AddEditIndividualInstituteInformationDetails";
export const UpdateIndividualOthersInformation = "IndividualMember/UpdateIndividualOthersInformation;"
export const AddEditCommunicationProfile = "IndividualMember/AddEditCommunicationProfile";
export const GetIndividualCommunication = "IndividualMember/GetIndividualCommunication?id={0}";
export const UpdateIndividualInformationByIndividualId = "IndividualMember/UpdateIndividualInformationByIndividualId";
export const AddEditIndividualOtherInformation = "IndividualMember/UpdateIndividualOthersInformation";
export const GetIndividualFinanceReceiptInvoice = "IndividualMember/GetIndividualFinanceReceiptInvoice?id={0}&id2={1}&id3={2}";
export const GetIndividualFinancialInfo = "IndividualMember/GetIndividualFinancialInfo?id={0}";

// Individual Member Task List
export const GetAllIndividualMembershipTaskList = "IndividualMember/GetAllIndividualMembershipTaskList";
export const GetAllIndividualInformationDetailsById = "IndividualMember/GetAllIndividualInformationDetailsById?id={0}";
export const AcceptIndividualStudentApplication = "IndividualMember/AcceptIndividualStudentApplication";
export const RejectMemberApplication = "IndividualMember/RejectMemberApplication";
export const GetMembershipPricingForIndividual = "Common/GetMembershipPricingForIndividual";
export const SendReconciliationPaymentLinkToIndividualEducator = "IndividualMember/SendReconciliationPaymentLinkToIndividualEducator";
export const GeneratePaymentInvoiceForIndividual = "MembershipPayment/GeneratePaymentInvoiceForIndividual";
export const AddIndividualMembershipPaymentData = "MembershipPayment/AddIndividualMembershipPaymentData";

// Field Management 
export const GetAllTagTypeDropdown = "FieldManagement/GetAllTagTypeDropdown";
export const GetAllTagList = "FieldManagement/GetAllTagList";
export const DeleteTags = "FieldManagement/DeleteTags";
export const GetTagDetailById = "FieldManagement/GetTagDetailById?id={0}";
export const AddEditTag = "FieldManagement/AddEditTag";

export const GetAllProjectTypeDropdown = "FieldManagement/GetAllProjectTypeDropdown";
export const GetEmailTemplateList = "FieldManagement/GetEmailTemplateList";
export const DeleteEmailTemplate = "FieldManagement/DeleteEmailTemplate";
export const GetEmailTemplateById = "FieldManagement/GetEmailTemplateById?id={0}";
export const AddEditEmailTemplate = "FieldManagement/AddEditEmailTemplate";

export const GetDropdownRecordList = "FieldManagement/GetDropdownRecordList";
export const GetDropdownDatabyId = "FieldManagement/GetDropdownDatabyId?id={0}";
export const DeleteDropdownData = "FieldManagement/Deletedropdowndata";
export const AddEditDropdownData = "FieldManagement/AddEditDropdownData";
export const GetTableDataById = "FieldManagement/GetTableDataById?DropdownId={0}&TableDataId={1}";


// Entry Management
export const GetAllMarketingTrackingURList = "EntryManagement/GetAllMarketingTrackingURList";
export const GetAllMarketingTrackingURLPartnerList = "EntryManagement/GetAllMarketingTrackingURLPartnerList";
export const GetAllMarketingTrackingURLEventList = "EntryManagement/GetAllMarketingTrackingURLEventList?id={0}";
export const AddEditMarketingTrackingURL = "EntryManagement/AddEditMarketingTrackingURL";
export const DeleteMarketingTrackingURL = "EntryManagement/DeleteMarketingTrackingURL?id={0}";
export const GetMarketingTrackingURLDetailById = "EntryManagement/GetMarketingTrackingURLDetailById?id={0}";

export const GetFeedbackQuestionEntryDetailList = "EntryManagement/GetFeedbackQuestionEntryDetailList";
export const GetAllFeedbackAnswerListByEventId = "EntryManagement/GetAllFeedbackAnswerListByEventId?id={0}";

export const GetFormQuestionAnswerEntryDetailList = "EntryManagement/GetFormQuestionAnswerEntryDetailList";
export const GetAllFormQuestionAnswerListByEventId = "EntryManagement/GetAllFormQuestionAnswerListByEventId?id={0}";


export const DownloadBookingSheetByEventId = "Booking/DownloadBookingSheetByEventId?id={0}&id2={1}";

export const GetEntryDetailList = "EntryManagement/GetEntryDetailList";
export const DeleteEntryDetailsByIds = "EntryManagement/DeleteEntryDetailsByIds";
export const GetEntryDetailsById = "EntryManagement/GetEntryDetailsById?id={0}";
export const GetPriceDetailsForFormByEventId = "EntryManagement/GetPriceDetailsForFormByEventId?id={0}";
export const AddEditEntryDetails = "EntryManagement/AddEditEntryDetails";
export const GetLessonTimeSlotDetailsById = "EntryManagement/GetLessonTimeSlotDetailsById?id={0}";

export const GetEntryTimeSlotDetailForTaggingByEventId = "EntryManagement/GetEntryTimeSlotDetailForTaggingByEventId?id={0}";
export const GetAllDropDownForCampaignTagging = "EntryManagement/GetAllDropDownForCampaignTagging";
export const GetCampaignTaggingDetailsByEventId = "EntryManagement/GetCampaignTaggingDetailsByEventId?id={0}";
export const AddEditCampaignTaggingDetails = "EntryManagement/AddEditCampaignTaggingDetails"

export const GetAllDropdownsForFinanceAdhokInvoices = "Finance/GetAllDropdownsForFinanceAdhokInvoices";
export const FinanceGetAllPendingEventApprovalList = "Finance/FinanceGetAllPendingEventApprovalList";
export const financeEventInvoiceApproval = "Finance/financeEventInvoiceApproval";
export const GetSGTechAddressesForEntries = "EntryManagement/GetSGTechAddressesForEntries";

export const DeleteEntryTimeSlotById = "EntryManagement/DeleteEntryTimeSlotById";
export const AddEditOtherEntryDetails = "EntryManagement/AddEditOtherEntryDetails";
export const AddEditFormQuestion = "EntryManagement/AddEditFormQuestion";
export const GetFormQuestionByEventId = "EntryManagement/GetFormQuestionByEventId?id={0}";
export const GetOtherEntryDetailsByEventId = "EntryManagement/GetOtherEntryDetailsByEventId?id={0}";
export const AddEditEntryLessonTimeSlotDetails = "EntryManagement/AddEditEntryLessonTimeSlotDetails";
export const GetEntryPricingDetail = "EntryManagement/GetEntryPricingDetail";
export const AddEditEntryPricingDetail = "EntryManagement/AddEditEntryPricingDetail";
export const DeleteEntryPricingPackage = "EntryManagement/DeleteEntryPricingPackage";
export const GetLocationAreaByPostalCode = "EntryManagement/GetLocationAreaByPostalCode?id={0}";
export const UpdateLessonDetailsById = "EntryManagement/UpdateLessonDetailsById";
export const AddEditEventSectionDetails = "EntryManagement/AddEditEventSectionDetails";
export const GetEventSectionDetails = "EntryManagement/GetEventSectionDetails?id={0}";
export const AddEditEventFormPriceList = "EntryManagement/AddEditEventFormPriceList";
export const GetEntryProgrammeDetailById = "EntryManagement/GetEntryProgrammeDetailById?id={0}";
export const AddEditEntryProgrammeDetail = "EntryManagement/AddEditEntryProgrammeDetail";
export const DeleteEventQuestionById = "EntryManagement/DeleteEventQuestionById?id={0}";
export const DeleteEventAnswerById = "EntryManagement/DeleteEventAnswerById?id={0}";
export const DeleteSpeakerById = "EntryManagement/DeleteSpeakerById?id={0}";
export const DeleteAdditionalDescriptionById = "EntryManagement/DeleteAdditionalDescriptionById?id={0}";
export const DeleteProgrammeTimeById = "EntryManagement/DeleteProgrammeTimeById?id={0}";
export const GetMembershipPeriods = "Common/GetMembershipPeriods?id={0}";
export const GetMembershipPeriods2 = "Common/GetMembershipPeriods2";


// Batch Job
export const GetAllWeeklyBatchJobRecords = "BatchJob/GetAllWeeklyBatchJobRecords";
export const GetAllMonthlyBatchJobRecords = "BatchJob/GetAllMonthlyBatchJobRecords";
export const GenerateWeeklyBatchJob = "BatchJob/GenerateWeeklyBatchJob?id={0}";
export const GenerateMonthlyBatchJob = "BatchJob/GenerateMonthlyBatchJob?id={0}";
export const DownloadSheetByBatchJobId = "BatchJob/DownloadSheetByBatchJobId?id={0}";
export const GetAllChartsOfAccountCodeList = "BatchJob/GetAllChartsOfAccountCodeList";
export const GetAllDropdownsForBatchJob = "BatchJob/GetAllDropdownsForBatchJob";
export const AddEditChartsOfAccountCode = "BatchJob/AddEditChartsOfAccountCode";
export const GetAllWeeklyPaymentRecociliationRecords = "BatchJob/GetAllWeeklyPaymentRecociliationRecords"
export const GenerateWeeklyPaymentReconciliation = "BatchJob/GenerateWeeklyPaymentReconciliation?id={0}";
export const DownloadSheetByXeroPaymentReconciliationId = "BatchJob/DownloadSheetByXeroPaymentReconciliationId?id={0}";

// Membership Reports
export const GenerateMembershipReport = "BatchJob/GenerateMembershipReport";
export const GetAllMembershipReportList = "BatchJob/GetAllMembershipReportList";
export const DownloadSheetByMembershipReportId = "BatchJob/DownloadSheetByMembershipReportId?id={0}";
// AR Aging Reports
export const GetAllARAgingReportList = "BatchJob/GetAllARAgingReportList";
export const GenerateARAgingReport = "BatchJob/GenerateARAgingReport";
export const DownloadARAgingSheetByARAgingReportId = "BatchJob/DownloadARAgingSheetByARAgingReportId?id={0}";
// GST Reports
export const GetAllGSTReportList = "BatchJob/GetAllGSTReportList";
export const GenerateGSTReport = "BatchJob/GenerateGSTReport";
export const DownloadGSTSheetByGSTReportId = "BatchJob/DownloadGSTSheetByGSTReportId?id={0}";
// Membership Reports
export const GenerateOrganisationRoleReport = "BatchJob/GenerateOrganisationRoleReport";
export const GetAllOrganisationRoleReportList = "BatchJob/GetAllOrganisationRoleReportList";
export const DownloadSheetByOrganisationRoleReportId = "BatchJob/DownloadSheetByOrganisationRoleReportId?id={0}";
export const DownloadSheetForXeroInvoiceExtractionRecords = "Finance/DownloadSheetForXeroInvoiceExtractionRecords";
// Voucher Management
export const GetAllVoucherCodeList = "Finance/GetAllVoucherCodeList";
export const GetVoucherCodeDetailsById = "Finance/GetVoucherCodeDetailsById?id={0}";
export const ActiveInActiveVoucherCode = "Finance/ActiveInActiveVoucherCode";
export const GetAllMemberListForVoucher = "Finance/GetAllMemberListForVoucher";
export const GetAllIndividualMemberListForVoucher = "Finance/GetAllIndividualMemberListForVoucher";
export const AddEditVoucherCodeDetails = "Finance/AddEditVoucherCodeDetails";
export const GetAllChapterListForVoucher = "Finance/GetAllChapterListForVoucher";
export const ApplyVoucherCodeForMembershipPayment = "Finance/ApplyVoucherCodeForMembershipPayment";
// For Voucher Reports
export const GetAllVoucherCodeReportList = "Finance/GetAllVoucherCodeReportList";
export const GenerateVoucherCodeReport = "Finance/GenerateVoucherCodeReport";
export const DownloadSheetByVoucherCodeReportId = "Finance/DownloadSheetByVoucherCodeReportId?id={0}";
//Extract Invoice
export const GetAllExtractInvoicesList = "Finance/GetAllExtractInvoicesList";
export const FinanceGetInvoice = "Finance/FinanceGetInvoice?path={0}";
export const FinanceGetCreditNoteInvoice = "Finance/FinanceGetCreditNoteInvoice?id={0}";
// Adhok Invoice In Finance 
export const AddEditFinanceAdhokInvoiceDetail = "Finance/AddEditFinanceAdhokInvoiceDetail";
export const GetFinanceAdhocInvoiceList = "Finance/GetFinanceAdhocInvoiceList";
export const GetFinanceAdhokInvoiceDetail = "Finance/GetFinanceAdhokInvoiceDetail?id={0}";
export const AddEditFinanceExternalServiceDetail = "Finance/AddEditFinanceExternalServiceDetail";
export const GetFinanceExternalServiceDetailById = "Finance/GetFinanceExternalServiceDetailById?id={0}";
export const AddDrawdownForExternalServiceItem = "Finance/AddDrawdownForExternalServiceItem";
export const GetFinanceCreditNoteById = "Finance/GetFinanceCreditNoteById?id={0}";
export const GetGSTRate = "Common/GetGST";
export const ZipInvoices = "Finance/ZipInvoices";
// For Deferred Income Reports
export const GetAllDeferredIncomeReportList = "Finance/GetAllDeferredIncomeReportList";
export const DownloadSheetByDeferredIncomeReportId = "Finance/DownloadSheetByDeferredIncomeReportId?id={0}";
export const GenerateDeferredIncomeReport = "Finance/GenerateDeferredIncomeReport";

// For Transaction Reports
export const GetAllTransactionReportList = "Finance/GetAllTransactionReportList";
export const GenerateTransactionReport = "Finance/GenerateTransactionReport";
export const DownloadSheetByTransactionReportId = "Finance/DownloadSheetByTransactionReportId?id={0}";
export const GetTransactionRecordList = "Finance/GetTransactionRecordList";
export const DownloadSheetForTransactionRecord = "Finance/DownloadSheetForTransactionRecord";

//bookings 
export const GetbookingsList = "Booking/GetbookingsList";
export const GetAllDropdownsForIndividualBooking = "Booking/GetAllDropdownsForIndividualBooking?id={0}";
export const GetBookingDetailsById = "Booking/GetBookingDetailsById?id={0}";
export const GetBookingDetailsByBookingId = "Booking/GetBookingDetailsByBookingId?attendeeId={0}&bookingId={1}";
export const GetBookingCancellationList = "Booking/GetBookingCancellationList";
export const GetBookingCancellationDetailsById = "Booking/GetBookingCancellationDetailsById?id={0}";
export const GetInvoiceReceiptPdfByBookingId = "IndividualMember/GetInvoiceReceiptPdfByBookingId?id={0}&type={1}"
export const DeleteEventPaymentReconcilation = "Booking/DeleteEventPaymentReconcilation?id={0}";

//batchjob 
export const FinanceUpdateStatusForCreditNote = "Finance/FinanceUpdateStatusForCreditNote";
export const FinanceAddCreditNotePaymentProofMapping = "Finance/FinanceAddCreditNotePaymentProofMapping";
export const AcceptTotalExternalInvoicePaidAmountInReconciliation = "Booking/AcceptTotalExternalInvoicePaidAmountInReconciliation?id={0}";
//Credit note for individual
export const FinanceGetCreditNoteInvoiceForIndividual = "Finance/FinanceGetCreditNoteInvoiceForIndividual?id={0}";
export const GetCreditNoteInfoForIndividualMember = "Finance/GetCreditNoteInfoForIndividualMember?id={0}&id2={1}";
export const GetCancellationReasonForCreditNoteForIndividualMember = "Finance/GetCancellationReasonForCreditNoteForIndividualMember";
export const AddFinanceCreditNoteForIndividual = "Finance/AddFinanceCreditNoteForIndividual";

//for renewal
export const AcceptTotalMembershipPaidAmountInReconciliation_ForConvert = "Finance/AcceptTotalMembershipPaidAmountInReconciliation"
export const DownloadBookingInvoiceByBookingId = "Booking/DownloadBookingInvoiceByBookingId?id={0}&type={1}";

