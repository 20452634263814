// Import Libraries and Components
import React from 'react';
import DropdownSelect from '../../../../../components/dropdown/Dropdown';
import CalculationChart from '../../../../../components/membership/membershipTaskList/CalculationChart';
import QuotationSectionWithBorder from '../../../../../components/membership/membershipTaskList/QuotationSectionWithBorder';
import SectionWithBorder from '../../../../../components/membership/membershipTaskList/SectionWithBorder';
import ModalBasic from '../../../../../pages/component/ModalBasic';
import moment from 'moment';
import {
    TextInput,
    DateInput,
    CheckboxInput,
} from "../../../../../components/allTypesInputComponents/AllTypeInputComponents";
import ValidationText from "../../../../../utils/validation/ValidationText";
import { StatusType } from "../../../../../utils/Constants"
import ButtonLoader from "../../../../../components/common/buttonLoader/ButtonLoader";
import { formatPrice } from '../../../../../components/common/FormatePrice';

function MembershipTaskListProcess(props) {
    const handleChangeInPriceTable = (value, name, year, tableName) => {
        props.handleChangeInPriceTable(value, name, year, tableName);
    };
    return (
        <div className='membership-tasklist-main'>
            <ModalBasic
                id="add-edit-modal"
                className="membership-add-edit-modal"
                modalOpen={props.setAddEditModalOpen}
                setModalOpen={props.setModalOpen}
                title="Membership Task List Process"
            >
                <div className="2xl:px-14 px-4 lg:px-14 pt-4">
                    <div className="">

                        <SectionWithBorder title="Individual Account Information">
                            <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">
                                {/* <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Designation{" "}
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                type="designation"
                                                name="designation"
                                            value={props.taskListDetail.designation}
                                            identity="Task_List_Detail"
                                            handleChange={props.handleChange}
                                            onBlur={(e) => props.validateField("designation")}
                                            <ValidationText error={props.validStateTaskListDetails.error.designation} />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                First Name
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="flex items-center">
                                                <div className="w-[130px] individual-f-name">
                                                    <DropdownSelect
                                                        drpIdentity={"Task_List_Salutation"}
                                                        optionArray={props.salutation}
                                                        setFilterParameters={props.setFilterParameters}
                                                        value={props.taskListDetail.salutionId}
                                                    // disabled={props.isOrganisationModalEditable !== true}
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <TextInput
                                                        placeholder=""
                                                        name="firstName"
                                                        identity="Task_List_Detail"
                                                        handleChange={props.handleChange}
                                                        value={props.taskListDetail.firstName}
                                                    // onBlur={(e) => props.validateField("firstName", "Organisation_Key_Role")}
                                                    // disabled={props.isOrganisationModalEditable !== true}
                                                    // isCurserAllowed={props.isOrganisationModalEditable !== true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Last Name
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    placeholder=""
                                                    name="lastName"
                                                    identity="Task_List_Detail"
                                                    handleChange={props.handleChange}
                                                    value={props.taskListDetail.lastName}
                                                // onBlur={(e) => props.validateField("firstName", "Organisation_Key_Role")}
                                                // disabled={props.isOrganisationModalEditable !== true}
                                                // isCurserAllowed={props.isOrganisationModalEditable !== true}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.companyName} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Year of Birth <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    placeholder=""
                                                    name="yearOfBirth"
                                                    identity="Task_List_Detail"
                                                    handleChange={props.handleChange}
                                                    value={props.taskListDetail.yearOfBirth}
                                                // onBlur={(e) => props.validateField("yearOfBirth", "Organisation_Key_Role")}
                                                // disabled={props.isOrganisationModalEditable !== true}
                                                // isCurserAllowed={props.isOrganisationModalEditable !== true}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.yearOfBirth} /> */}
                                            </div>
                                            {/* <DateInput
                                                name="incorporationDate"
                                                identity="Task_List_Detail"
                                                type="number"
                                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                            // value={moment(props.taskListDetail.incorporationDate).format("YYYY-MM-DD")}
                                            // handleChange={props.handleChange}
                                            // onBlur={(e) => props.validateField("incorporationDate")}
                                            /> */}
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Mobile Number
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    placeholder=""
                                                    type="number"
                                                    name="contactNo"
                                                    value={props.taskListDetail.contactNo}
                                                    identity="Task_List_Detail"
                                                    handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("contactNo")}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.contactNo} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Email Address <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <TextInput
                                                placeholder=""
                                                name="email"
                                                value={props.taskListDetail.email}
                                                identity="Task_List_Detail"
                                                handleChange={props.handleChange}
                                            // onBlur={(e) => props.validateField("email")}
                                            />
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.email} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Nationality
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <DropdownSelect
                                                // disabled={true}
                                                drpIdentity={"Nationality_Type"}
                                                optionArray={props.nationalityType}
                                                setFilterParameters={props.setFilterParameters}
                                                value={props.taskListDetail.nationalityId}
                                            />
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-span-12'>
                                    <div className='flex justify-end'>
                                        <button className="text-lg font-bold btn btn-pink text-white">Save</button>
                                    </div>
                                </div> */}
                            </div>
                        </SectionWithBorder>

                        <SectionWithBorder title="Institution Information">
                            <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">

                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Name of Institute
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <DropdownSelect
                                                    disabled={true}
                                                    drpIdentity={"Select_Institute"}
                                                    optionArray={props.institutes}
                                                    setFilterParameters={props.setFilterParameters}
                                                    value={props.taskListDetail.instituteId}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Other Institute Name
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    placeholder=""
                                                    name="otherInstituteName"
                                                    value={props.taskListDetail.otherInstituteName}
                                                    identity="Task_List_Detail"
                                                    handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("otherInstituteName")}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.otherInstituteName} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Institution Website
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    placeholder=""
                                                    name="instituteWebsite"
                                                    value={props.taskListDetail.instituteWebsite}
                                                    identity="Task_List_Detail"
                                                    handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("instituteWebsite")}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.instituteWebsite} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    props && props.taskListDetail.membershipTypeId === 5 ?
                                        <>
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Country of Studies <span className="text-[#AA3361]">*</span>
                                                        </label>
                                                        <DropdownSelect
                                                            // disabled={true}
                                                            drpIdentity={"Select_CountryOfStudies"}
                                                            optionArray={props.studiesType}
                                                            setFilterParameters={props.setFilterParameters}
                                                            value={props.taskListDetail.countryOfStudiesId}
                                                        />
                                                        {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Pursuing Qualification
                                                            <span className="text-[#AA3361]">*</span>
                                                        </label>
                                                        <div className="disabled-input">
                                                            <DropdownSelect
                                                                // disabled={true}
                                                                drpIdentity={"Select_Pursuing_Qualification"}
                                                                optionArray={props.pursuingQualifiedType}
                                                                setFilterParameters={props.setFilterParameters}
                                                                value={props.taskListDetail.pursuingQualificationId}
                                                            />
                                                            {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Specialisation/Disipline <span className="text-[#AA3361]">*</span>
                                                        </label>
                                                        <TextInput
                                                            placeholder=""
                                                            name="specialisation"
                                                            value={props.taskListDetail.specialisation}
                                                            identity="Task_List_Detail"
                                                            handleChange={props.handleChange}
                                                        // onBlur={(e) => props.validateField("specialisation")}
                                                        />
                                                        {/* <ValidationText error={props.validStateTaskListDetails.error.specialisation} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Years of Commencement
                                                            <span className="text-[#AA3361]">*</span>
                                                        </label>
                                                        <DropdownSelect */}
                                            {/* // disabled={true} */}
                                            {/* drpIdentity={"Year_Of_Commencement"}
                                                            optionArray={props.yearOfCompletedType}
                                                            setFilterParameters={props.setFilterParameters}
                                                            value={props.taskListDetail.yearOfCommencementId}
                                                        /> */}
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                            {/* </div>
                                                </div>
                                            </div> */}
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Year of Completion
                                                            <span className="text-[#AA3361]">*</span>
                                                        </label>
                                                        <DropdownSelect
                                                            // disabled={true}
                                                            drpIdentity={"Year_Of_Completion"}
                                                            optionArray={props.yearOfCompletedType}
                                                            setFilterParameters={props.setFilterParameters}
                                                            value={props.taskListDetail.yearOfCompletionId}
                                                        />

                                                        {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            htmlFor="default"
                                                        >
                                                            Matriculation Number
                                                            <span className="text-[#AA3361]">*</span>
                                                        </label>
                                                        <TextInput
                                                            placeholder=""
                                                            name="matriculationNumber"
                                                            value={props.taskListDetail.matriculationNumber}
                                                            identity="Task_List_Detail"
                                                            handleChange={props.handleChange} */}
                                            {/* // onBlur={(e) => props.validateField("matriculationNumber")}
                                                        /> */}
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.matriculationNumber} /> */}
                                            {/* </div>
                                                </div>
                                            </div> */}
                                        </>
                                        :
                                        null
                                }
                                {
                                    props && props.taskListDetail.membershipTypeId === 6 ?
                                        <>
                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="flield-sec">
                                                    <label
                                                        className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                        htmlFor="default"
                                                    >
                                                        Designation
                                                        <span className="text-[#AA3361]">*</span>
                                                    </label>
                                                    <label className="text-[#000] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">

                                                    </label>
                                                    <TextInput
                                                        placeholder=""
                                                        name="designation"
                                                        value={props.taskListDetail.designation}
                                                        identity="Task_List_Detail"
                                                        handleChange={props.handleChange}
                                                    // onBlur={(e) => props.validateField("designation")}
                                                    />
                                                    {/* <ValidationText error={props.validStateInstituteInformation.error.designation} /> */}
                                                </div>
                                            </div>

                                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                                <div className="flield-sec">
                                                    <label
                                                        className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                        htmlFor="default"
                                                    >
                                                        Job Position
                                                        <span className="text-[#AA3361]">*</span>
                                                    </label>

                                                    <div className="flield-sec">
                                                        <DropdownSelect
                                                            drpIdentity={"Select_Job_Position"}
                                                            optionArray={props.jobPositionType}
                                                            setFilterParameters={props.setFilterParameters}
                                                            value={props.taskListDetail.jobPositionId}
                                                        />
                                                        {/* <ValidationText error={props.validStateInstituteInformation.error.jobPositionId} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        null
                                }
                                <div className="xl:col-span-6 md:col-span-6 col-span-12 pt-3">
                                    <div className="grid grid-cols-12 gap-6 mt-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12 flex justify-end">
                                            <button
                                                onClick={() => props.navigateToViewIndividualMember()}
                                                className="col-start-1 col-end-3 text-xl font-bold text-[#901b4c] btn-pink-border w-[350px] p-3">
                                                View Individual Information
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-span-12'>
                                    <div className='flex justify-end'>
                                        <button className="text-lg font-bold btn btn-pink text-white">Save</button>
                                    </div>
                                </div> */}
                            </div>
                        </SectionWithBorder>

                        <SectionWithBorder title="Membership Subscription">
                            <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">


                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Membership Type
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <DropdownSelect
                                                disabled={true}
                                                drpIdentity={"Membership_Type"}
                                                optionArray={props.membershipType}
                                                setFilterParameters={props.setFilterParameters}
                                                value={props.taskListDetail.membershipTypeId}
                                            />
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Updated By
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <div className="disabled-input">
                                                <TextInput
                                                    disabled={true}
                                                    placeholder=""
                                                    name="updatedById"
                                                    value={props.taskListDetail.updatedById === 1 ? "Admin" :
                                                        props.taskListDetail.updatedById === 2 ? "Web" : null
                                                    }
                                                    identity="Task_List_Detail"
                                                // handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("updatedById")}
                                                />
                                                {/* <ValidationText error={props.validStateTaskListDetails.error.updatedById} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Subscription Start Date
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <DateInput
                                                name="subscriptionJoinDate"
                                                identity="Task_List_Detail"
                                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]"
                                                value={moment(props.taskListDetail.subscriptionJoinDate).format("YYYY-MM-DD")}
                                                handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("subscriptionJoinDate")}
                                                disabled={true}
                                            />
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.subscriptionJoinDate} /> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Subscription End Date
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <DateInput
                                                name="subscriptionEndDate"
                                                identity="Task_List_Detail"
                                                type="number"
                                                className="date-pickers form-input rounded-none w-full cursor-not-allowed bg-[#e9e9ea] shadow-red py-3 text-lg"
                                                value={moment(props.taskListDetail.subscriptionEndDate).format("YYYY-MM-DD")}
                                                handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("subscriptionEndDate")}
                                                disabled={true}
                                            />
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.subscriptionEndDate} /> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                    <div className="grid grid-cols-12 gap-6">
                                        <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                            <label
                                                className="filter-lable block text-size-18 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                htmlFor="default"
                                            >
                                                Last Updated Date
                                                <span className="text-[#AA3361]">*</span>
                                            </label>
                                            <DateInput
                                                name="incorporationDate"
                                                identity="Task_List_Detail"
                                                type="number"
                                                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]"
                                                // value={moment(props.taskListDetail.incorporationDate).format("YYYY-MM-DD")}
                                                // handleChange={props.handleChange}
                                                // onBlur={(e) => props.validateField("incorporationDate")}
                                                disabled={true}
                                            />
                                            {/* <ValidationText error={props.validStateTaskListDetails.error.incorporationDate} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SectionWithBorder>

                        {
                            props && props.taskListDetail.membershipTypeId === 6 ?
                                <SectionWithBorder title="Membership Pricing">
                                    <div className="grid grid-cols-12 gap-4 w-full items-center pt-7 px-7 pb-7">
                                        <div className='col-span-12'>
                                            <div className="overflow-x-auto pb-5">
                                                <table className="border border-[#d0d0d0] w-full  ">
                                                    <tr className="bg-[#AA3361] border border-[#d0d0d0] text-white text-xl font-bold ">
                                                        <th className="text-left whitespace-nowrap border  border-[#d0d0d0] text-white py-3 md:px-5 px-3 w-[40%]">
                                                            Membership Type
                                                        </th>
                                                        <th className="whitespace-nowrap border text-center border-[#d0d0d0] text-white py-2 md:px-5 px-2 w-[20%]">
                                                            Term
                                                        </th>
                                                        <th className="whitespace-nowrap border text-center border-[#d0d0d0] text-white py-2 md:px-5 px-2 w-[20%]">
                                                            Entrance Fee
                                                        </th>
                                                        <th className="whitespace-nowrap border text-center border-[#d0d0d0] text-white py-2 md:px-5 px-2 w-[20%]">
                                                            Annual Fee
                                                        </th>
                                                    </tr>
                                                    <tbody>
                                                        <tr className="border border-[#d0d0d0]">
                                                            <td className="whitespace-nowrap border bg-[#e9e9e9] border-[#d0d0d0] text-left text-lg py-3 md:px-5 px-3 w-[33.333%]">
                                                                Associate Educator Member
                                                            </td>
                                                            <td className="whitespace-nowrap border bg-[#e9e9e9] border-[#d0d0d0] text-center text-lg py-2 md:px-2 px-2 w-[33.333%]">
                                                                {props.membershipPeriods != null ? props.membershipPeriods[0].name : 'Ending 30th April 2025' }
                                                            </td>
                                                            <td className="whitespace-nowrap border bg-[#e9e9e9] border-[#d0d0d0] text-center text-lg py-2 md:px-2 px-2 w-[33.333%]">
                                                                {formatPrice(props.educatorPricingDetail.entranceFee)}
                                                            </td>
                                                            <td className="whitespace-nowrap border bg-[#e9e9e9] border-[#d0d0d0] text-center text-lg py-2 md:px-2 px-2 w-[33.333%]">
                                                                <span className="text-[#181818]">{formatPrice(props.educatorPricingDetail.annualFee)}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* <div className="col-span-12">
                                            <div className="flex justify-end mb-5">
                                                <button className="btn-disabled-border cursor-not-allowed opacity-50 col-start-1 col-end-3 text-lg font-bold text-[#901b4c] w-[280px] p-3 ">Send Payment Link</button>
                                            </div>
                                        </div> */}
                                    </div>
                                </SectionWithBorder>
                                :
                                null
                        }
                        <div className='col-span-12'>
                            <div className='flex justify-between mb-5'>
                                {
                                    props && props.isRejectLoading ?
                                        <ButtonLoader />
                                        :
                                        <button
                                            className="btn-disabled-border col-start-1 col-end-3 text-lg font-bold text-[#901b4c] w-[280px] p-3"
                                            onClick={() => props.rejectMemberApplication()}
                                        >
                                            Reject Application
                                        </button>
                                }

                                {
                                    props && props.taskListDetail.membershipTypeId === 5 ?
                                        <>
                                            {
                                                props && props.isAcceptLoading ?
                                                    <ButtonLoader />
                                                    :
                                                    <button
                                                        className="text-lg font-bold btn btn-pink text-white"
                                                        onClick={() => props.acceptIndividualStudentApplication(props.taskListDetail.membershipTypeId)}
                                                    >
                                                        Accept Application
                                                    </button>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                props && props.isAcceptLoading ?
                                                    <ButtonLoader />
                                                    :
                                                    <button
                                                        className="text-lg font-bold btn btn-pink text-white"
                                                        onClick={() => props.generatePaymentInvoiceForIndividual()}
                                                    >
                                                        Send Payment Link
                                                    </button>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
        </div>
    )
}
export default MembershipTaskListProcess;
